var mySwiper = new Swiper ('.swiper-container', {
   // Optional parameters
   slidesPerView: 'auto',
   observer: true,
   watchOverflow: true,
   // If we need pagination
   pagination: {
     el: '.swiper-pagination',
	 clickable: 'true'
   },

   // Navigation arrows
   navigation: {
     nextEl: '.swiper-button-next',
     prevEl: '.swiper-button-prev',
   },

   // And if we need scrollbar
   scrollbar: {
     el: '.swiper-scrollbar',
   },
 })
